import React from "react";
import Estrella from "../assets/svg/estre.svg";
import Corazon from "../assets/svg/cora.svg";
import Frame from "../assets/img/Frame.png";
import Aumenta from "../assets/svg/aunmenta.svg";
export default function CambiateAFP() {
  return (
    <div className="container_reasons">
      <div className="media p-2 align-items-center">
        <span className="text-gray " style={{display: "inline-block"}}>
          <strong className="d-block">¿Por qué cambiarme a AFP Modelo?</strong>
        </span>
        <img
          src={Frame}
          className="ml-2 desktop"
          width="32"
          height="32"
          alt=""
          style={{display: "inline-block"}}
        />
        <img src={Frame} className="ml-2 mobile icon" alt="" />
      </div>
      <div className="media p-2 align-items-center">
        <img
          src={Aumenta}
          className="mr-3 desktop"
          width="32"
          height="32"
          alt=""
          style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}
        />
        <div className="media-body text-mut ml-2" style={{display: "inline-block"}}>
          <img src={Aumenta} className="mr-3 mobile icon" alt="" />
          <span>
            <strong className="d-block"> Primeros en rentabilidad</strong>  En todos los fondos en 2024.
          </span>
        </div>
      </div>
      <div className="media p-2 align-items-center">
        <img
          src={Estrella}
          className="mr-3 desktop"
          width="32"
          height="32"
          alt=""
          style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}
        />
        <div className="media-body text-mut ml-2" style={{display: "inline-block"}}>
          <img src={Estrella} className="mr-3 mobile icon" alt="" />
          <span>
          <strong className="d-block">La mejor experiencia de servicio</strong> Según Ranking PXI 2024, sector AFP.
          
          </span>
           
        </div>
      </div>
      <div className="media p-2 align-items-center">
        <img
          src={Corazon}
          className="mr-3 desktop "
          width="32"
          height="32"
          alt=""
          style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}
        />
        <div className="media-body text-mut ml-2" style={{display: "inline-block"}}>
          <img src={Corazon} className="mr-3 mobile icon" alt="" />
          <span>
            <strong className="d-block">La AFP con más afiliados</strong>
            Más personas confían en nosotros.
          </span>
        </div>
      </div>
     
    </div>
  );
}
